<template>
  <th-page-wrapper
    class="warehouses-edit"
    color="var(--secondary-color)"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <warehouses-form ref="form" @new-item="goToEdit()" />
  </th-page-wrapper>
</template>

<script>
import WarehousesForm from './components/form.vue'
import th from '@tillhub/javascript-sdk'

export default {
  name: 'WarehousesEdit',
  components: {
    WarehousesForm
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'inventory:warehouses',
        returnedPath: { name: 'warehouses-list' },
        isNew: this.isNew
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },
    goBack() {
      this.$router.push({ name: 'warehouses-list' })
    },
    goToEdit() {
      this.$refs.form?.payload?.id
        ? this.$router.push({
            name: 'warehouses-edit',
            params: { id: this.$refs.form.payload.id }
          })
        : this.goBack()
    },
    async handleDelete() {
      const payload = this.$refs.form.payload
      const confirm = await this.$askToDelete(payload.name || payload.id)
      if (confirm) this.deleteWarehouse(payload)
    },
    async deleteWarehouse(payload) {
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.warehouse.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.warehouse.singular')
      })

      try {
        const inst = th.warehouses()
        await inst.delete(payload.id)
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.goBack()
      } catch (err) {
        const errorMessageDescription = this.$t(
          'resource.warehouse.delete_fail.description'
        )
        this.$logException(err, {
          message: `${errorMessage}: ${errorMessageDescription}`
        })
      }
    }
  }
}
</script>

<style scoped></style>
