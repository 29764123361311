<template>
  <th-wrapper
    collapsable
    :title="$t('components.th_addresses_input.sections.address.title')"
  >
    <!-- Addresses -->
    <el-row :gutter="40">
      <el-col
        v-for="(address, index) in form.addresses"
        :key="index"
        :md="12"
        class="mb-4"
      >
        <address-row
          :ref="'address-' + index"
          :model-value="address"
          :required-fields="requiredFields"
          :readonly="readonly"
          :is-main-address-required="isMainAddressRequired"
          @update:modelValue="(v) => (form.addresses[index] = v)"
          @delete="deleteAddress(index)"
        />
      </el-col>
    </el-row>

    <div v-if="!readonly" class="-mt-3">
      <hr
        v-if="canAddDeliveryAddresses || canAddBillingAddresses"
        class="mb-6 mt-1"
      />
      <!-- Add delivery address -->
      <el-button
        v-if="canAddDeliveryAddresses"
        text
        plain
        icon="Plus"
        @click="handleAddAddress('delivery')"
      >
        {{ $t('components.th_addresses_input.sections.delivery.add') }}
      </el-button>

      <!-- Add billing address -->
      <el-button
        v-if="canAddBillingAddresses"
        text
        plain
        icon="Plus"
        class="ml-0"
        @click="handleAddAddress('billing')"
      >
        {{ $t('components.th_addresses_input.sections.billing.add') }}
      </el-button>
    </div>
  </th-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import { ADDRESS_FIELDS } from '@/constants'
import AddressRow from './address/address-row.vue'
import get from 'just-safe-get'

export default {
  name: 'ThAddressesInputV2',

  components: {
    AddressRow
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isMainAddressRequired: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      requiredFields: Object.values(ADDRESS_FIELDS)
    }
  },

  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },

    canAddBillingAddresses() {
      return (
        this.form.addresses.filter((a) => a.type === 'billing').length === 0
      )
    },

    canAddDeliveryAddresses() {
      return (
        this.form.addresses.filter((a) => a.type === 'delivery').length === 0
      )
    },

    defaultCountry() {
      return get(
        this.clientAccountConfiguration,
        'tenant.address.country',
        'DE'
      )
    }
  },

  watch: {
    'form.addresses': {
      handler() {
        // Always show local address
        if (!this.form.addresses.find((a) => a.type === 'local')) {
          this.handleAddAddress('local')
        }
      },
      immediate: true
    }
  },

  methods: {
    handleAddAddress(type) {
      const newAddress = {
        type,
        country: this.defaultCountry,
        postal_code: '',
        region: '',
        locality: '',
        street_number: '',
        street: '',
        lines: null
      }
      // Keep local address on top
      if (type === 'local') this.form.addresses.unshift(newAddress)
      else this.form.addresses.push(newAddress)
    },

    deleteAddress(index) {
      this.form.addresses.splice(index, 1)
    },

    async validate() {
      // Validate all address rows
      let valid = true
      for (const key in this.$refs) {
        if (key.includes('address') && this.$refs[key].length > 0) {
          if (!(await this.$refs[key][0].validate())) valid = false
        }
      }
      return valid
    }
  }
}
</script>
