<template>
  <div>
    <div v-for="(item, index) in modelValue" :key="index" class="mb-3">
      <el-row :gutter="20">
        <!-- Type -->
        <el-col :span="8" :xs="24">
          <el-form-item
            :label="
              index === 0
                ? $t('components.th_phonenumbers_input.placeholders.type')
                : null
            "
            for="allowedTypes"
          >
            <el-select
              v-if="allowedTypes"
              id="allowedTypes"
              v-model="localPhonenumbers[index].type"
              class="w-full"
            >
              <el-option
                v-for="type in allowedTypes"
                :key="type.value"
                :label="type.label"
                :value="type.value"
                :disabled="shouldDisableType(type.value)"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <!-- Phone number -->
        <el-col :span="10" :xs="12">
          <el-form-item
            :label="
              index === 0
                ? $t(
                    'components.th_phonenumbers_input.placeholders.phone_number'
                  )
                : null
            "
            for="phone_number"
          >
            <el-input
              id="phone_number"
              v-model="localPhonenumbers[index].value"
            />
          </el-form-item>
        </el-col>

        <!-- Actions -->
        <el-col :span="6" :xs="12">
          <div class="flex items-center">
            <!-- Delete -->
            <el-button
              plain
              icon="Delete"
              :class="{
                'mt-th-input-title': index === 0
              }"
              class="-ml-3 el-button--text-icon"
              @click="deletePhonenumber(index)"
            />

            <!-- Add -->
            <el-button
              v-if="index === modelValue.length - 1"
              plain
              class="el-button--primary-icon"
              icon="Plus"
              :class="{
                'mt-th-input-title': index === 0
              }"
              :disabled="isMax"
              @click="addNew(index)"
            />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
function genInitialPhonenumber() {
  return {
    type: null,
    value: null
  }
}

export default {
  name: 'ThPhonenumbersInput',
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: () => []
    },
    allowedTypes: {
      type: Array,
      required: false,
      default: () => []
    },
    withInitialFields: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonText: {
      type: String,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      localPhonenumbers: []
    }
  },
  computed: {
    // we will not be able to add more phonenumbers then there are types, since the API will throw 422 errors. Hence we do even allow that here
    // validation for type MECE-ness will be done below
    isMax() {
      if (!this.localPhonenumbers || !this.allowedTypes) return false
      if (this.localPhonenumbers.length === this.allowedTypes.length)
        return true
      else return false
    },
    // only allow types that are not included in any local phonenumber already
    availableTypes() {
      const available = this.allowedTypes.filter((item) => {
        return !this.localPhonenumbers.find(
          (phonenumber) => phonenumber.type === item.value
        )
      })

      return available
    }
  },
  watch: {
    modelValue: function (newVal) {
      this.localPhonenumbers = newVal
      if (this.withInitialFields && !this.localPhonenumbers.length)
        this.addNew()
    }
  },
  created() {
    this.localPhonenumbers = this.modelValue
    if (this.withInitialFields && !this.localPhonenumbers.length) this.addNew()
  },
  methods: {
    addNew() {
      this.localPhonenumbers.push(genInitialPhonenumber())
    },
    deletePhonenumber(index) {
      this.localPhonenumbers.splice(index, 1)
    },
    shouldDisableType(v) {
      return !this.availableTypes.find((type) => v === type.value)
    }
  }
}
</script>
