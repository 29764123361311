<template>
  <el-form ref="form" v-loading="loading" :model="form" :rules="rules">
    <th-wrapper :title="$t('common.titles.general_info.title')" class="m-8">
      <el-row :gutter="0">
        <el-col class="form-column" :xs="24">
          <el-row :gutter="20">
            <el-col :lg="8" :sm="24">
              <el-form-item
                :label="$t('pages.warehouses.edit.form.properties.name.label')"
                prop="name"
                for="name"
              >
                <el-input id="name" v-model="form.name" />
              </el-form-item>
              <el-form-item
                :label="
                  $t('pages.warehouses.edit.form.properties.short_name.label')
                "
                prop="short_name"
                for="short_name"
              >
                <el-input id="short_name" v-model="form.short_name" />
              </el-form-item>
              <el-form-item
                :label="
                  $t('pages.warehouses.edit.form.properties.custom_id.label')
                "
                prop="custom_id"
                for="custom_id"
              >
                <el-input id="custom_id" v-model="form.custom_id" />
              </el-form-item>
              <el-form-item prop="phonenumbers">
                <phonenumbers-input
                  ref="phonenumbersInput"
                  v-model="phonenumbers"
                  with-initial-fields
                  :button-text="$t('pages.warehouses.form.buttons.add')"
                  :allowed-types="allowedPhoneTypes"
                />
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="24">
              <el-form-item
                :label="
                  $t('pages.warehouses.edit.form.properties.barcode.label')
                "
                prop="barcode"
                for="barcode"
              >
                <el-input id="barcode" v-model="form.barcode" />
              </el-form-item>
              <el-form-item
                :label="
                  $t('pages.warehouses.edit.form.properties.capacity.label')
                "
                prop="capacity"
                for="capacity"
              >
                <th-number-input
                  id="capacity"
                  v-model="form.capacity"
                  :locale="$i18n.locale"
                />
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="24">
              <el-form-item
                :label="$t('common.forms.labels.image')"
                prop="images"
              >
                <th-image-upload-v2
                  id="image"
                  v-model="form.images"
                  resource="branches"
                  fill-type="contain"
                  image-key="1x"
                  height="290px"
                  icon="PictureRounded"
                  :title="
                    $t(
                      'pages.products.edit.form.sections.standard.images.add_image'
                    )
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </th-wrapper>

    <addresses-input-v2
      ref="address"
      v-model="form"
      data-testid="warehouses-address"
      class="m-8"
    />
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import { isEmptyObject, isEmptyArray } from '@/utils/objects'
import { isEmptyString } from '@/utils/strings'
import { defaultImages } from '@/utils/people-default-images.js'
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import AddressesInputV2 from '@/components/tillhub/addresses-input-v2'
import PhonenumbersInput from '@/components/tillhub/phonenumbers-input'

function genInitialData() {
  return {
    name: null,
    short_name: null,
    custom_id: null,
    phonenumbers: {},
    addresses: [],
    images: {},
    capacity: null,
    barcode: null
  }
}

export default {
  name: 'WarehousesForm',
  components: {
    AddressesInputV2,
    PhonenumbersInput
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      valid: false,
      phonenumbers: [],
      form: genInitialData(),
      customIdError: false,
      rules: {
        name: [
          {
            required: true,
            message: this.$t('pages.warehouses.edit.form.rules.name.required'),
            trigger: 'blur'
          },
          {
            min: 3,
            max: 128,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 3,
              max: 128
            }),
            trigger: 'blur'
          }
        ],
        custom_id: [
          {
            required: true,
            message: this.$t('pages.warehouses.edit.form.rules.name.required'),
            trigger: 'blur'
          },
          { validator: this.validateCustomIdError, trigger: 'blur' }
        ]
      },
      payload: {}
    }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    isNew() {
      if (this.$route.params.id && this.$route.params.id === 'new') return true

      return !this.$route.params.id
    },
    allowedAddressesTypes() {
      return [
        {
          value: 'billing',
          label: this.$t('common.forms.labels.addresses.types.billing')
        },
        {
          value: 'local',
          label: this.$t('common.forms.labels.addresses.types.local')
        },
        {
          value: 'delivery',
          label: this.$t('common.forms.labels.addresses.types.delivery')
        }
      ]
    },
    allowedPhoneTypes() {
      return [
        {
          value: 'line_main',
          label: this.$t(
            'pages.warehouses.edit.form.properties.phonenumbers.types.line_main.label'
          )
        },
        {
          value: 'line_1',
          label: this.$t(
            'pages.warehouses.edit.form.properties.phonenumbers.types.line_1.label'
          )
        },
        {
          value: 'line_2',
          label: this.$t(
            'pages.warehouses.edit.form.properties.phonenumbers.types.line_2.label'
          )
        }
      ]
    },
    image() {
      if (this.form.images && this.form.images.avatar)
        return this.form.images.avatar

      return defaultImages.neutral
    }
  },
  watch: {
    visible: function (newValue, oldValue) {
      if (oldValue && !newValue) this.resetForm()
      if (!newValue) this.resetData()
      if (newValue && !this.isNew) this.fetch(this.$route.params.id)
    },
    'form.custom_id': function () {
      if (this.customIdError) this.customIdError = false
    }
  },
  async mounted() {
    if (!this.isNew) await this.fetch(this.$route.params.id)
  },
  methods: {
    async fetch(id) {
      try {
        this.loading = true
        const { data = {} } = await th.warehouses().getOne(id)

        if (data.id) {
          this.handleItem(data)
        }
      } catch (err) {
        const errorMessage = this.$t('common.error.action.read.single', {
          resource: this.$t('common.resource.warehouse.singular')
        })
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    handleItem(item) {
      this.payload = item
      const obj = {
        ...pick(item, Object.keys(this.form))
      }
      // addresses cannot be null for the addresses-input component
      if (obj.addresses === null) obj.addresses = []

      // convert phonenumber object from fetched payload
      // to array (required for phonenumbers-input)
      if (item.phonenumbers) {
        this.phonenumbers = Object.keys(item.phonenumbers).reduce(
          (acc, key) =>
            acc.concat({ type: key, value: item.phonenumbers[key] }),
          []
        )
      } else {
        this.phonenumbers = [{ type: null, value: null }]
      }

      this.form = obj
    },
    submitForm() {
      this.validate('form', (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: this.$t('common.forms.warning.invalid_input.message')
          })
        }

        if (this.isNew) return this.create()
        this.alter(this.payload.id)
      })
    },

    validate(formName = 'form', cb) {
      this.$refs[formName].validate((valid) => {
        return cb(valid)
      })
    },
    resetForm(formName) {
      if (!this.$refs[formName]) return
      this.$refs[formName].resetFields()
      this.form.addresses = []
      this.form.phonenumbers = {}
      this.phonenumbers = [{ type: null, value: null }]
      this.images = {}
    },
    resetData() {
      this.form = genInitialData()
    },
    async alter() {
      const successMessage = this.$t('common.success.action.update.single', {
        resource: this.$t('common.resource.warehouse.singular')
      })
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.warehouse.singular')
      })

      const payload = {
        ...this.payload,
        ...this.form
      }

      try {
        this.loading = true
        const body = this.makeHandleableBody(payload)
        const { data = {} } = await th.warehouses().put(payload.id, body)

        if (data.id) {
          this.handleItem(data)
        }

        this.loading = false
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.$emit('handled-item')
        this.$emit('altered-item')
      } catch (err) {
        this.checkCustomIdError(err)
        this.loading = false
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    async create() {
      const successMessage = this.$t('common.success.action.create.single', {
        resource: this.$t('common.resource.warehouse.singular')
      })
      const errorMessage = this.$t('common.error.action.create.single', {
        resource: this.$t('common.resource.warehouse.singular')
      })

      const payload = {
        ...this.form
      }

      try {
        this.loading = true
        const body = this.makeHandleableBody(payload)
        const { data = {} } = await th.warehouses().create(body)

        if (data.id) {
          this.handleItem(data)
        }
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.$emit('handled-item')
        this.$emit('new-item')

        if (this.navigationAfterCreation === 'edit') {
          this.$router.push({
            name: 'warehouses-edit',
            params: { id: data.id }
          })
        } else {
          this.$router.push({ name: 'warehouses-list' })
        }
      } catch (err) {
        this.checkCustomIdError(err)
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    checkCustomIdError(err) {
      const errorMsg = safeGet(err, 'properties.error.response.data.msg') || ''
      // duplicate key value violates unique constraint \"warehouses_custom_id_unique_idx\
      if (errorMsg.includes('unique')) {
        this.customIdError = true
        this.$refs.form.validate()
      }
    },
    validateCustomIdError(rule, value, callback) {
      if (this.customIdError) {
        callback(
          new Error(
            this.$t('pages.warehouses.error.custom_id.message', {
              custom_id: this.form.custom_id
            })
          )
        )
      } else {
        callback()
      }
    },
    makeHandleableBody(payload) {
      // convert phonenumber from array (needed for phonenumbers-input)
      // to object (according to required staff schema)
      this.form.phonenumbers = payload.phonenumbers = this.phonenumbers.reduce(
        (acc, number) => {
          if (number.type && number.value) acc[number.type] = number.value
          return acc
        },
        {}
      )

      const cleanedForm = pick(payload, Object.keys(this.form))

      // replace empty objects, emtpy arrays and empty strings with null
      return Object.keys(cleanedForm).reduce((result, key) => {
        const isEmpty =
          isEmptyObject(cleanedForm[key]) ||
          isEmptyArray(cleanedForm[key]) ||
          isEmptyString(cleanedForm[key])
        result[key] = isEmpty ? null : cleanedForm[key]
        return result
      }, {})
    }
  }
}
</script>

<style scoped></style>
